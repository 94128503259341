<template>
  <section>
    <section
      class="order-delivery-place bg-white border-radius20 box-shaddow20 container px-0 mb-5"
    >
      <div
        class="order-delivery-place-head d-sm-flex justify-content-between align-items-baseline border-bottom p-3"
      >
        <div>
          <svg
            class="mt-n2"
            width="30"
            height="30"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 480 480"
            style="enable-background: new 0 0 480 480"
            xml:space="preserve"
          >
            <path
              fill="#666"
              d="M408.886,198.978l-76.188-31.708c-0.089-0.037-0.181-0.059-0.27-0.093c-0.281-0.107-0.563-0.212-0.852-0.293
                  c-0.149-0.042-0.3-0.067-0.449-0.103c-0.239-0.056-0.478-0.114-0.72-0.152c-0.167-0.026-0.334-0.036-0.502-0.054
                  c-0.228-0.024-0.455-0.051-0.686-0.059c-0.174-0.006-0.347,0.001-0.521,0.004c-0.223,0.003-0.446,0.006-0.669,0.024
                  c-0.18,0.015-0.356,0.043-0.535,0.067c-0.215,0.029-0.43,0.059-0.643,0.102c-0.186,0.038-0.368,0.088-0.551,0.137
                  c-0.143,0.038-0.286,0.062-0.427,0.106l-98.587,30.78l-13.153-4.082c23.014-34.822,35.119-70.166,35.119-102.848
                  C249.254,40.736,208.504,0,158.415,0c-50.071,0-90.807,40.736-90.807,90.807c0,30.75,10.726,63.855,31.167,96.666l-27.658,11.505
                  c-3.729,1.551-6.16,5.194-6.16,9.233V470c0,3.343,1.671,6.465,4.452,8.32c1.667,1.112,3.602,1.68,5.549,1.68
                  c1.301,0,2.607-0.254,3.843-0.769l72.328-30.121l72.321,30.121c0.001,0,0.001,0,0.002,0.001c0.28,0.116,0.566,0.216,0.855,0.307
                  c0.091,0.029,0.184,0.052,0.275,0.078c0.201,0.057,0.403,0.108,0.607,0.152c0.105,0.023,0.209,0.043,0.314,0.063
                  c0.204,0.037,0.409,0.066,0.615,0.091c0.094,0.011,0.188,0.025,0.282,0.033c0.294,0.027,0.59,0.043,0.887,0.044
                  c0.002,0,0.004,0,0.006,0h0.001h0.001c0.471,0,0.943-0.036,1.414-0.103c0.017-0.003,0.035-0.003,0.053-0.006
                  c0.45-0.066,0.896-0.168,1.338-0.298c0.035-0.01,0.07-0.018,0.105-0.028c0.024-0.007,0.048-0.012,0.072-0.02l98.089-30.638
                  l72.832,30.325c1.236,0.515,2.542,0.768,3.843,0.768c1.947,0,3.882-0.568,5.549-1.68c2.781-1.854,4.452-4.977,4.452-8.32V208.21
                  C415.043,204.172,412.614,200.53,408.886,198.978z M141.132,431.61l-56.173,23.393V214.881l24.71-10.278
                  c0.174-0.073,0.337-0.16,0.505-0.241c11.528,15.834,22.974,28.357,30.958,36.434V431.61z M158.444,229.767
                  c-18.681-18.377-70.835-75.388-70.835-138.96C87.609,51.764,119.374,20,158.415,20c39.06,0,70.837,31.764,70.837,70.807
                  C229.253,154.38,177.118,211.39,158.444,229.767z M217.297,455.002l-56.165-23.393V253.127c1.388-0.387,2.711-1.075,3.862-2.072
                  c0.79-0.684,18.093-15.782,36.896-40.259c0.002,0.001,0.003,0.001,0.005,0.002l15.402,4.779V455.002z M318.856,430.925
                  L237.297,456.4V215.564l81.559-25.463V430.925z M395.043,455.004l-56.188-23.395V191.496l56.188,23.384V455.004z"
            />
            <path
              fill="#666"
              d="M158.417,46.442c-24.451,0-44.343,19.902-44.343,44.365s19.892,44.365,44.343,44.365
                  c24.462,0,44.365-19.902,44.365-44.365S182.88,46.442,158.417,46.442z M158.417,115.172c-13.423,0-24.343-10.93-24.343-24.365
                  s10.92-24.365,24.343-24.365c13.435,0,24.365,10.93,24.365,24.365S171.852,115.172,158.417,115.172z"
            />
          </svg>
          <span class="fontsize-large weight-bold text-color-444"
            >مکان تحویل سفارش</span
          >
        </div>
        <button
          @click="newAddress"
          class="add-address-btn bg-color-theme2 p-2  Add-new-address"
          data-toggle="modal"
          data-target="#AddAddress1"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8.3273V15.6537"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.6667 11.9905H8.33333"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="fontsize-small pl-2  mr-2"
            >افزودن آدرس جدید</span
          >
        </button>
        <!-- Modal -->
        <address-modal
          @new-address="addNewAddress"
          :mode="mode"
          v-model="address"
        ></address-modal>
      </div>
      <section class="">
        <div v-if="addresses == null" class="text-center py-5">
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9999 2H12.0333C16.7197 2.01842 20.5183 5.84197 20.4999 10.5223V10.6153C20.4366 13.96 18.4258 16.7356 16.7504 18.4746C16.2736 18.9718 15.7661 19.4489 15.2427 19.8944C14.9292 20.1619 14.4586 20.1251 14.1908 19.812C13.923 19.498 13.9607 19.028 14.2742 18.7605C14.7589 18.3475 15.2304 17.9037 15.6739 17.4416C17.1649 15.8946 18.9527 13.4487 19.0071 10.5881C19.022 6.65842 15.8916 3.50574 12.0272 3.48996H11.9999C8.14862 3.48996 5.00768 6.61194 4.99276 10.4609C5.05861 12.3622 5.70138 14.1696 6.85256 15.6859C8.19604 17.4749 10.2341 19.3691 12.1712 20.6302C12.5171 20.8547 12.6146 21.316 12.3889 21.6615C12.2467 21.8807 12.007 22 11.7628 22C11.6232 22 11.4827 21.9614 11.3563 21.879C9.28224 20.5293 7.0993 18.5 5.66011 16.5839C4.32453 14.8238 3.57727 12.7235 3.5 10.5127C3.51844 5.78585 7.32848 2 11.9999 2ZM10.2552 10.6141C10.2552 11.5727 11.0367 12.354 11.9973 12.354C12.9579 12.354 13.7394 11.5727 13.7394 10.6141C13.7394 9.65474 12.9579 8.87337 11.9973 8.87337C11.5855 8.87337 11.2509 8.54012 11.2509 8.12883C11.2509 7.71665 11.5855 7.38341 11.9973 7.38341C13.7807 7.38341 15.2322 8.83215 15.2322 10.6141C15.2322 12.3952 13.7807 13.844 11.9973 13.844C10.2139 13.844 8.76241 12.3952 8.76241 10.6141C8.76241 10.202 9.09696 9.86872 9.50879 9.86872C9.92061 9.86872 10.2552 10.202 10.2552 10.6141Z"
              fill="#200E32"
            />
          </svg>
          <h5 class="weight-bold">آدرسی وجود ندارد</h5>
          <p class="text-color-666 fontsize13">
            برای انجام سفارش می توانید آدرس های منتخب خود را اینجا اضافه کنید.
          </p>
          <button
            v-if="!mediaQueries.mobileSize"
            @click="newAddress"
            class="bg-color-theme2 Add-new-address py-3 px-4"
            data-toggle="modal"
            data-target="#AddAddress1"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 8.3273V15.6537"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.6667 11.9905H8.33333"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="mr-1 fontsize14 weight-bold"
              >افزودن آدرس جدید</span
            >
          </button>
        </div>
        <div v-else class="pt-3 pl-3 border-bottom row mx-0">
          <div
            class="col-md-6 col-12 in-address"
            v-for="(address, index) in addresses"
            :key="address.id"
            @click="
              (e) => {
                addressSelect(e, index);
              }
            "
          >
            <cart-address-item
              :address="address"
              :selectedAddress="selectedAddress"
              :index="index"
              @delete-address="deleteAddress(address.id)"
              @edit-address="editAddress(address)"
              :disabled="disabled"
            >
            </cart-address-item>
          </div>
        </div>
      </section>

      <div class="p-3">
        <h6 class="text-color-444 text-right fontsize-large weight-bold mb-3">
          شیوه ارسال
        </h6>
        <div class="sending-method  mb-3">
          <article
            v-for="shipping in shippings"
            @click="(e) => onShippingClicked(e, shipping)"
            :key="shipping.id"
            :class="{
              disabled: !availableShippings.find(
                (item) => item.id == shipping.id
              ),
            }"
            class="d-flex border-radius15 justify-content-between bg-color-eai p-3 mb-1"
            :style="
              !availableShippings.find((item) => item.id == shipping.id)
                ? 'cursor:not-allowed'
                : 'cursor:pointer'
            "
          >
            <div class="d-flex">
              <div class="align-self-center ml-2">
                <label class="address-checked-container">
                  <input
                    type="radio"
                    class="sendMethodeBtn"
                    :checked="
                      selectedShipping && selectedShipping.id == shipping.id
                    "
                    name="sendMethodeBtn"
                  />
                  <span class="address-checkmark"></span>
                </label>
              </div>
              <!-- <input type="radio" class="sendMethodeBtn mxl-1" :checked="selectedShipping && selectedShipping.id == shipping.id" name="sendMethodeBtn"/> -->
              <img
                class="shipping-img mr-4"
                v-if="shipping.logo"
                :src="shipping.logo.url"
                :alt="shipping.name"
              />
              <svg
                v-else
                class="ml-2 mr-4"
                width="40"
                height="40"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 422.518 422.518"
                style="enable-background: new 0 0 422.518 422.518"
                xml:space="preserve"
              >
                <path
                  fill="#666"
                  d="M422.512,215.424c0-0.079-0.004-0.158-0.005-0.237c-0.116-5.295-4.368-9.514-9.727-9.514h-2.554l-39.443-76.258
              c-1.664-3.22-4.983-5.225-8.647-5.226l-67.34-0.014l2.569-20.364c0.733-8.138-1.783-15.822-7.086-21.638
              c-5.293-5.804-12.683-9.001-20.81-9.001h-209c-5.255,0-9.719,4.066-10.22,9.308l-2.095,16.778h119.078
              c7.732,0,13.836,6.268,13.634,14c-0.203,7.732-6.635,14-14.367,14H126.78c0.007,0.02,0.014,0.04,0.021,0.059H10.163
              c-5.468,0-10.017,4.432-10.16,9.9c-0.143,5.468,4.173,9.9,9.641,9.9H164.06c7.168,1.104,12.523,7.303,12.326,14.808
              c-0.216,8.242-7.039,14.925-15.267,14.994H54.661c-5.523,0-10.117,4.477-10.262,10c-0.145,5.523,4.215,10,9.738,10h105.204
              c7.273,1.013,12.735,7.262,12.537,14.84c-0.217,8.284-7.109,15-15.393,15H35.792v0.011H25.651c-5.523,0-10.117,4.477-10.262,10
              c-0.145,5.523,4.214,10,9.738,10h8.752l-3.423,35.818c-0.734,8.137,1.782,15.821,7.086,21.637c5.292,5.805,12.683,9.001,20.81,9.001
              h7.55C69.5,333.8,87.3,349.345,109.073,349.345c21.773,0,40.387-15.545,45.06-36.118h94.219c7.618,0,14.83-2.913,20.486-7.682
              c5.172,4.964,12.028,7.682,19.514,7.682h1.55c3.597,20.573,21.397,36.118,43.171,36.118c21.773,0,40.387-15.545,45.06-36.118h6.219
              c16.201,0,30.569-13.171,32.029-29.36l6.094-67.506c0.008-0.091,0.004-0.181,0.01-0.273c0.01-0.139,0.029-0.275,0.033-0.415
              C422.52,215.589,422.512,215.508,422.512,215.424z M109.597,329.345c-13.785,0-24.707-11.214-24.346-24.999
              c0.361-13.786,11.87-25.001,25.655-25.001c13.785,0,24.706,11.215,24.345,25.001C134.89,318.131,123.382,329.345,109.597,329.345z
               M333.597,329.345c-13.785,0-24.706-11.214-24.346-24.999c0.361-13.786,11.87-25.001,25.655-25.001
              c13.785,0,24.707,11.215,24.345,25.001C358.89,318.131,347.382,329.345,333.597,329.345z M396.457,282.588
              c-0.52,5.767-5.823,10.639-11.58,10.639h-6.727c-4.454-19.453-21.744-33.882-42.721-33.882c-20.977,0-39.022,14.429-44.494,33.882
              h-2.059c-2.542,0-4.81-0.953-6.389-2.685c-1.589-1.742-2.337-4.113-2.106-6.676l12.609-139.691l28.959,0.006l-4.59,50.852
              c-0.735,8.137,1.78,15.821,7.083,21.637c5.292,5.806,12.685,9.004,20.813,9.004h56.338L396.457,282.588z"
                />
              </svg>
              <div class="d-flex flex-column">
                <span class="fontsize-medium text-color-444">{{
                  shipping.name
                }}</span>
                <span class="fontsize-mini text-color-999">{{
                  shipping.description
                }}</span>
              </div>
            </div>

            <div class="text-center border-right pr-3">
              <h6 class="mb-1 fontsize-small text-color-444">
                هزینه ارسال و
                <br />
                بسته بندی :
              </h6>
              <span
                class="fontsize-mini text-color-666"
                v-if="getShippingPrice(shipping)"
                >{{ getShippingPrice(shipping) | price }}</span
              >
              <span
                class="fontsize-mini text-color-666"
                v-else-if="getShippingPrice(shipping) === 0"
              >
                {{
                  shipping.extra_description
                    ? shipping.extra_description
                    : "رایگان"
                }}
              </span>
              <span class="fontsize-mini text-color-666" v-else>?</span>
            </div>
          </article>
        </div>
        <div
          class="d-flex justify-content-between align-items-baseline px-sm-3 px-0 py-2"
        >
          <button
            id="prev-btn-step-2"
            class="continue-buy-process py-2 w-25 ml-2 fontsize-medium text-center"
            @click="prevPage"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="10"
              height="10"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g
                fill="none"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              >
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g fill="#ffffff">
                  <path
                    d="M62.39031,10.32c-0.90031,0.01344 -1.74688,0.36281 -2.39188,0.99438l-20.72062,20.55937c-1.34375,1.34375 -1.35719,3.52063 -0.02688,4.86438l48.96625,49.36937l-49.35594,48.96625c-1.35719,1.34375 -1.37062,3.52063 -0.02687,4.86438l20.55937,20.72062c1.34375,1.35719 3.52063,1.37063 4.86438,0.02688l72.53562,-71.94438c1.34375,-1.34375 1.35719,-3.52062 0.01344,-4.87781l-71.93094,-72.52219c-0.65844,-0.67187 -1.55875,-1.03469 -2.48594,-1.02125z"
                  ></path>
                </g>
              </g>
            </svg>
            مرحله قبل
          </button>
          <button
            id="next-btn-step-2"
            class="continue-buy-process py-2 fontsize-medium w-25 text-center"
            @click="nextPagePachage"
            :disabled="!addresses"
          >
            <!--  @click="nextPagePachage" -->
            مرحله بعد
          </button>
          <!-- <PackingPopup
            @next-page="nextPagePachage"
            @new-address="addNewAddress"
            :mode="mode"
            v-model="address"
          ></PackingPopup> -->
          <div
            class="modal fade mobile-friendly"
            id="PackingPopup"
            tabindex="-1"
            aria-labelledby="AddAddressLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            >
              <div class="modal-content">
                <div class="modal-header text-right">
                  <button
                    type="button"
                    class="close ml-0"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h6
                    class="modal-title weight-bold text-color-444 mx-auto"
                    id="AddAddressLAbel"
                  >
                    انتخاب شیوه بسته بندی
                  </h6>
                </div>
                <div class="modal-body add-package-type text-center px-4">
                  <button
                    @click="j = i"
                    class="packageItem pr-2 pt-2 pb-2 pl-2"
                    :class="{ selectedStyles: j == i }"
                    v-for="(option, i) in packages"
                    :key="i"
                  >
                    <div>
                      <div class="circleSelected"><div></div></div>
                      <div>
                        <span>{{ option.name }}</span>
                        <span class="font-weight-bold" v-if="option.price !== 0"
                          >{{ option.price | price }} تومن</span
                        >
                        <span class="font-weight-bold" v-else>رایگان</span>
                      </div>
                    </div>
                    <div>
                      <img :src="option.image.url" class="rounded shadow-sm" />
                    </div>
                  </button>
                </div>
                <div class="packing-confirm-button-container">
                  <button
                    class="packing-confirm-button"
                    @click="nextPagePachage"
                  >
                    تایید
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import AddressModal from "@/parts/Front/components/AddressModal";
import PackingPopup from "@/parts/Front/components/PackingPopup";
import CartAddressItem from "@/parts/Front/components/cart/CartAddressItem";
import { setShipping, getShippingPrice } from "@/libraries/functions";
import { debouncedNotify } from "@/libraries/VueMethods";
import closeModalMixin from "@/libraries/mixins/closeModalMixin";
import { BRow, BCol, BFormGroup } from "bootstrap-vue";
export default {
  name: "CartStep2",
  inject: ["mediaQueries"],
  components: {
    AddressModal,
    PackingPopup,
    CartAddressItem,
    BRow,
    BCol,
    BFormGroup,
  },
  mixins: [closeModalMixin],
  data() {
    return {
      j: 0,
      // packingOptions: [
      //   {
      //     id: 2,
      //     name: "\u0628\u0633\u062a\u0647 \u06f1 111",
      //     price: 1000,
      //     order: 1,
      //     status: 1,
      //     description:
      //       "\u062a\u0648\u0636\u06cc\u062d\u0627\u062a \u0628\u0633\u062a\u0647 \u06f1",
      //     creator_id: 1,
      //     updater_id: 1,
      //     created_at: "2022-08-04 04:29:18",
      //     updated_at: "2022-08-04 04:29:18",
      //     image: {
      //       id: 7335,
      //       type: "image",
      //       name: "Screenshot_20220610_170157.png",
      //       url:
      //         "http:\/\/localhost:8000\/storage\/220ad255-8c62-4e3c-b1a4-a50acdffbe30\/Screenshot_20220610_170157.png",
      //       conversions: [],
      //     },
      //   },
      //   {
      //     id: 1,
      //     name: "\u0628\u0633\u062a\u0647 \u06f1 2999",
      //     price: 2000,
      //     order: 9,
      //     status: 1,
      //     description:
      //       "\u062a\u0648\u0636\u06cc\u062d\u0627\u062a \u0628\u0633\u062a\u0647 \u06f1",
      //     creator_id: 1,
      //     updater_id: 1,
      //     created_at: "2022-08-04 04:29:18",
      //     updated_at: "2022-08-04 04:29:18",
      //     image: {
      //       id: 7335,
      //       type: "image",
      //       name: "Screenshot_20220610_170157.png",
      //       url:
      //         "http:\/\/localhost:8000\/storage\/220ad255-8c62-4e3c-b1a4-a50acdffbe30\/Screenshot_20220610_170157.png",
      //       conversions: [],
      //     },
      //   },
      //   {
      //     id: 4,
      //     name: "\u0628\u0633\u062a\u0647 \u06f1 1444",
      //     price: 480000,
      //     order: 4,
      //     status: 1,
      //     description:
      //       "\u062a\u0648\u0636\u06cc\u062d\u0627\u062a \u0628\u0633\u062a\u0647 \u06f1",
      //     creator_id: 1,
      //     updater_id: 1,
      //     created_at: "2022-08-04 04:29:18",
      //     updated_at: "2022-08-04 04:29:18",
      //     image: {
      //       id: 7335,
      //       type: "image",
      //       name: "Screenshot_20220610_170157.png",
      //       url:
      //         "http:\/\/localhost:8000\/storage\/220ad255-8c62-4e3c-b1a4-a50acdffbe30\/Screenshot_20220610_170157.png",
      //       conversions: [],
      //     },
      //   },
      //   {
      //     id: 6,
      //     name: "\u0628\u0633\u062a\u0647 \u06f1 222",
      //     price: 30000,
      //     order: 2,
      //     status: 1,
      //     description:
      //       "\u062a\u0648\u0636\u06cc\u062d\u0627\u062a \u0628\u0633\u062a\u0647 \u06f1",
      //     creator_id: 1,
      //     updater_id: 1,
      //     created_at: "2022-08-04 04:29:18",
      //     updated_at: "2022-08-04 04:29:18",
      //     image: {
      //       id: 7335,
      //       type: "image",
      //       name: "Screenshot_20220610_170157.png",
      //       url:
      //         "http:\/\/localhost:8000\/storage\/220ad255-8c62-4e3c-b1a4-a50acdffbe30\/Screenshot_20220610_170157.png",
      //       conversions: [],
      //     },
      //   },
      // ],
      selectedAddress: this.value,
      mode: "create",
      disabled: false,
      address: {},
    };
  },
  props: {
    value: Object,
    shippings: Array,
    selectedShipping: Object,
    total: Number,
    totalQuantity: Number,
  },

  created() {
    if (this.$store.getters["front/getProvincesData"] == null) {
      this.$store.dispatch("front/getProvincesDataFromServer");
    }
  },
  computed: {
    //    packages() {
    //   if (this.$store.getters["front/getGiftPackages"]) {
    //     return this.$store.getters["front/getGiftPackages"];
    //   } else {
    //     return null;
    //   }
    // },
    packages() {
      // اگه سمت فرانت سرت میشه
      //     return this.packingOptions.sort((a, b) => {
      //     return a.order - b.order ;
      //   });

      // اگه سمت بک سرت میشه
      return this.$store.getters["front/getGiftPackages"];
      // return this.packingOptions;
    },
    addresses() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.user.addresses != ""
      ) {
        console.log(
          this.$store.getters["front/getHomeData"].user.user.addresses
        );
        return this.$store.getters["front/getHomeData"].user.user.addresses;
      } else {
        return null;
      }
    },
    provinces() {
      return this.$store.getters["front/getProvincesData"];
    },
    availableShippings() {
      if (!this.selectedAddress) {
        return this.shippings;
      }

      return setShipping(this.selectedAddress, this.shippings);
    },
  },
  watch: {
    addresses(addresses) {
      if (!this.selectedAddress && addresses != "") {
        this.selectedAddress = addresses[0];
      }
    },
    selectedAddress(newAddress) {
      this.$emit("input", newAddress);
    },
    value(newVal) {
      this.selectedAddress = newVal;
    },
  },
  methods: {
    nextPagePachage() {
      // this.$store.commit("front/setPackagePrice", this.packages[this.j].price);
      // this.$store.commit("front/setPackageID", this.packages[this.j].id);

      // window.$(".modal-open")[0].classList.remove("modal-open");
      // window.$(".modal-backdrop")[0].classList.remove("modal-backdrop");
      return this.$emit("next-page");
    },
    showPopupPlz() {
      window.$("#PackingPopUp").modal("show");
    },
    getShippingPrice(shipping) {
      if (!this.selectedAddress) {
        return null;
      }
      return getShippingPrice(
        this.selectedAddress,
        shipping,
        this.total,
        this.totalQuantity
      );
    },
    addNewAddress(newAddress) {
      if (this.mode == "create") {
        this.$store.commit("front/newAddress", newAddress);
      } else {
        this.$store.commit("front/editAddress", newAddress);
      }
    },
    nextPage() {
      if (!this.selectedAddress) {
        this.$root.notify("لطفا ابتدا یک آدرس را انتخاب کنید", "warning");
        return;
      }
      if (!this.selectedShipping) {
        this.$root.notify("لطفا ابتدا یک روش ارسال را انتخاب کنید", "warning");
        return;
      }
      if (!this.isShippingValid(this.selectedShipping)) {
        this.$root.notify("روش ارسال انتخاب شده نامعتبر است", "warning");
        return;
      }
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
    newAddress() {
      this.address = {
        province: null,
        city: null,
        first_name: "",
        last_name: "",
        postal_code: "",
        address: "",
        mobile: "",
      };
      this.mode = "create";
    },
    async deleteAddress(id) {
      this.disabled = true;
      try {
        const response = await this.$store.dispatch("front/deleteAddress", {
          id,
        });
        if (response) {
          this.$root.notify(response.data.message, "success");
          this.$store.commit("front/deleteAddress", id);
        }
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    addressSelect(e, index) {
      if (!this.$root.hasClass(e.target, "not-in-address")) {
        this.selectedAddress = this.addresses[index];
      }
    },
    async editAddress(item) {
      let address = { ...item };
      address.province = address.city.province;
      this.address = address;
      this.mode = "edit";
      window.$("#AddAddress1").modal("show");
    },
    onShippingClicked(e, shippingWantsToSelect) {
      if (!this.selectedAddress) {
        this.$root.debouncedNotify(
          "لطفا ابتدا یک آدرس را انتخاب کنید",
          "warning"
        );
        e.preventDefault();
        return;
      }
      // شیپینگ هایی که برای این آدرس قابل انتخاب است
      let ok = this.isShippingValid(shippingWantsToSelect);
      if (!ok) {
        this.$root.debouncedNotify(
          "متاسفانه این روش ارسال برای آدرس انتخاب شده ممکن نیست",
          "warning"
        );
        e.preventDefault();
        return;
      }
      this.$emit("shippingSelected", shippingWantsToSelect);
    },
    isShippingValid(shipping) {
      let availableShippings = this.availableShippings;
      let ok = availableShippings.find((_shipping) => {
        return _shipping.id == shipping.id;
      });
      return ok;
    },
  },
  mounted() {
    if (!this.selectedAddress && this.addresses && this.addresses != "") {
      this.selectedAddress = this.addresses[0];
    }
    if (this.provinces == null) {
      this.$store.dispatch("front/getProvincesDataFromServer");
    }
    // Select default
    this.$nextTick(() => {
      if (
        !this.selectedShipping &&
        this.availableShippings &&
        this.availableShippings.length
      ) {
        this.$emit("shippingSelected", this.availableShippings[0]);
      }
    });
  },
};
</script>
<style scoped>
#next-btn-step-2{
  border-radius: 15px !important;
  background-color: var(--color-themeGreen2);
}
#prev-btn-step-2{
  border: 1px solid #000;
  border-radius: 15px !important;
  background-color: #fff !important;
  color: #000 !important;
}
.Add-new-address{
  padding: 10px 16px !important;
  border-radius: 10px !important;
  background-color: var(--color-theme-main);
  color: #fff !important;
}
.sending-method article.disabled {
  opacity: 0.7;
}
.packing-confirm-button-container {
  display: flex;
}
.packing-confirm-button {
  bottom: 0;
  width: 65%;
  font-size: 20px;
  color: white;
  padding: 13px 0;
  margin: 24px auto;
  background-color: black;
}
.add-package-type {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;
}
.packageItem {
  background-color: #eee;
  display: grid;
  grid-template-columns: 60% auto;
}
.packageItem img {
  width: 90%;
  height: 100px;
  aspect-ratio: 1/2;
}
.packageItem > div:first-child {
  align-self: center;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  gap: 50px;
}
.packageItem > div:first-child > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.packageItem > div:nth-child(2) {
  width: 80%;
  height: 80%;
  margin: auto;
  padding: 5px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
}
.packageItem div:nth-child(2) > span:first-child {
  color: #999;
}
#AddAddress1 .modal-header .close {
  margin-right: 0;
}
.add-address-modal .modal-body,
.add-address-modal .modal-dialog-scrollable .modal-content {
  overflow: unset;
}
.add-address-modal .multiselect__tags,
.add-address-modal .form-control {
  border: 1px solid var(--color-ea);
  border-radius: 10px;
}
.add-address-modal .multiselect__placeholder {
  color: #777;
}
.add-address-modal .multiselect,
.add-address-modal .multiselect__input,
.add-address-modal .multiselect__single,
.add-address-modal .form-control {
  font-size: 16px !important;
}

.add-address-modal .multiselect__option--highlight::after,
.add-address-modal .multiselect__option--highlight {
  background: var(--color-theme);
}

.add-address-modal
  .multiselect__option--selected.multiselect__option--highlight::after,
.add-address-modal
  .multiselect__option--selected.multiselect__option--highlight {
  background: var(--color-theme);
}
.add-address-modal .form-control {
  height: 42px;
  box-shadow: unset;
}
.shipping-img {
  width: 50px;
  height: auto;
  margin-left: 10px;
}
.sending-method .address-checkmark,
.sending-method .address-checkmark::after {
  border-radius: 25px;
}
@media screen and (max-width: 500px) {
  .shipping-img {
    height: 50px;
  }
}

.circleSelected {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 10px;
  background: white;
}
.circleSelected > div {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 5px;
}
.packageItem {
  transition: 0.5s;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 3px 24px -2px #ccc;
  color: #777;
}
.selectedStyles {
  /* background-color: #aaa; */
  transition: 0.5s;
}
.selectedStyles span {
  color: black !important;
}
.selectedStyles .circleSelected {
  background-color: black;
}
</style>
