<template>
  <div id="loginAndRegisterSection">
    <div class="w-100 d-flex justify-content-center">
      <AtlasLogo />
    </div>
    <div></div>
    <div id="welcomeToAtlas">
      <h4>به  کفش یونیک خوش آمدید</h4>
    </div>
    <LoginRegister :showFullForm="true" ref="LoginRegister" />
  </div>
</template>
<script>
import LoginRegister from "@/parts/Front/components/loginRegister/LoginRegister.vue";
import AtlasLogo from "@/parts/Front/components/AtlasLogo";
export default {
  components: {
    AtlasLogo,
    LoginRegister,
  },
  beforeDestroy() {
    this.$refs.LoginRegister.closeModal();
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped lang="scss">
#welcomeToAtlas {
  text-align: center;
  margin-top: 60px;
}
#welcomeToAtlas h4 {
  color: #095d0b;
}
#loginAndRegisterSection {
  max-width: 80%;
  margin: auto;
  margin-top: 2%;
}
/*
 */
$avatar-size: 32px;
$body-background: #353535;

@font-face {
  font-family: "ubuntu";
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"),
    url(https://fonts.gstatic.com/s/ubuntucondensed/v8/u-4k0rCzjgs5J7oXnJcM_0kACGMtT-Dfqw.woff2)
      format("woff2");
}

body {
  height: 100vh;
  margin: 0;
  background-color: $body-background;
  font-family: "ubuntu", Arial, sans-serif;
  overflow-x: hidden;
  display: grid;
  place-items: center;
}

a {
  text-decoration: none;
  color: #9ca0b1;
}

//.wrapper {
//text-align: center;
//    display: flex;
//    width: 100vw;
//    position: fixed;
//    top:0;
//    padding-top: 6px;
//    cursor: pointer;
//    background: white;
//    z-index: 5;
//  h3 {
//    color: #fff;
//    font-size: 26px;
//    font-family: "ubuntu";
//    text-transform: uppercase;
//    font-weight: 700;
//    font-family: "Josefin Sans", sans-serif;
//    background: linear-gradient(
//      to right,
//      #121314 10%,
//      #d4832b 50%,
//      #f88303 60%
//    );
//    background-size: auto auto;
//    background-clip: border-box;
//    background-size: 200% auto;
//    color: #fff;
//    background-clip: text;
//    text-fill-color: transparent;
//    -webkit-background-clip: text;
//    -webkit-text-fill-color: transparent;
//    animation: textclip 1.5s linear infinite;
//    display: inline-block;
//  }
//}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
// media screen
@media (max-width:420px){
  #welcomeToAtlas h4{
    font-size:large
  }
  .wrapper{
    justify-content: center;
  }
  #loginAndRegisterSection{
    margin-top: 20%;
  }
}
</style>
<style>
@media screen and (min-width: 768px) {
  #loginAndRegisterSection #InsertMobile {
    width: 450px!important;
  }
}
</style>
