<template>
  <div id="orderBox">
    <PagesPath :pathTitle1="'سفارش'" />
    <template v-if="status">
      <div style="margin: 5%">
        <b-skeleton type="input" />
        <hr />
        <b-skeleton />
        <hr />
        <b-skeleton />
        <hr />
        <b-skeleton />
        <hr />
        <b-skeleton />
      </div>
    </template>
    <template v-else>
      <div id="orderSection">
        <div class="showInPrint" id="headerInPrint">
          <img src="@/assets/images/logo.png" alt="atlasmode" />
          <span>فاکتور خرید کالا از  کفش یونیک</span>
          <span>کد سفارش:{{ " " + $route.params.id }}</span>
        </div>
        <div class="showInPrint" id="userInformation">
          <table v-if="user != null" id="customerTabel">
            <tbody>
              <tr>
                <th class="markCellHeader" scope="row" colspan="5">
                  مشخصات خریدار
                </th>
              </tr>
              <tr>
                <th scope="row" class="markCell">مشتری:</th>
                <td colspan="2" class="col_2">{{ user.full_name }}</td>
                <td class="markCell">شماره تماس :</td>
                <td scope="row" class="last_col">{{ user.mobile }}</td>
              </tr>
              <tr>
                <th scope="row" class="markCell">کد ملی:</th>
                <td colspan="2" class="col_2">{{ user.national_code }}</td>
                <td class="markCell">کد پستی :</td>
                <td scope="row" class="last_col">
                  {{ getCustomerAdderss().postal_code }}
                </td>
              </tr>

              <tr>
                <th scope="row" class="markCell">آدرس:</th>
                <td colspan="4">{{ getCustomerAdderss().address }}</td>
              </tr>
            </tbody>
          </table>

          <table id="atlasTabel" v-if="homeData != null">
            <tbody>
              <tr>
                <th class="markCellHeader" scope="row" colspan="5">
                  مشخصات فروشنده
                </th>
              </tr>
              <tr>
                <th scope="row" class="markCell">فروشنده:</th>
                <td colspan="2" class="col_2">
                  {{ homeData.settings.shop_info.shop_name }}
                </td>
                <td class="markCell">شماره تماس :</td>
                <td scope="row" class="last_col">
                  {{ homeData.settings.shop_info.shop_telephone }}
                </td>
              </tr>
              <tr>
                <th scope="row" class="markCell">استان/شهر:</th>
                <td colspan="2" class="col_2">گلستان / گرگان</td>
                <td class="markCell">کد پستی :</td>
                <td scope="row" class="last_col">
                  {{ homeData.settings.shop_info.shop_postal_code }}
                </td>
              </tr>

              <tr>
                <th scope="row" class="markCell">آدرس:</th>
                <td colspan="4">
                  {{ homeData.settings.shop_info.shop_address }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="itemTable">
          <table class="table">
            <thead>
              <tr id="trItemTable" class="text-color-444 fontsize14">
                <th class="hideIn768" scope="col">شناسه</th>
                <th scope="col">تاریخ</th>
                <th scope="col">محصول</th>
                <th scope="col">قیمت اصلی</th>
                <th scope="col">تخفیف</th>
                <th scope="col">تعداد</th>
                <th scope="col">قیمت نهایی</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.items" :key="item.id">
                <th class="hideIn768" scope="row">{{ item.id }}</th>
                <td scope="row">
                  {{ moment(item.created_at).format('HH:mm YYYY/MM/DD') }}
                </td>
                <td>
                  {{ item.product.title + " " + getVarietyTitle(item.extra) }}
                </td>
                <td>{{ item.amount | price }} تومان</td>
                <td>
                  <template v-if="item.discount_amount == null">
                    ندارد
                  </template>
                  <template v-else>
                    {{ item.discount_amount | price }}
                  </template>
                </td>
                <td>{{ item.quantity }}</td>
                <td>
                  {{
                    getSumPrice(item.amount, item.discount_amount, item.quantity) | price
                  }}
                </td>
              </tr>
              <template v-if="order.reservations.length != 0">
                <template v-for="reserv in order.reservations">
                  <tr v-for="item in reserv.items" :key="reserv.id + item.id">
                    <th class="hideIn768" scope="row">{{ item.id }}</th>
                    <td scope="row">
                      {{ moment(item.created_at).format('HH:mm YYYY/MM/DD') }}
                    </td>
                    <td style="min-width: 118px;">
                      {{ item.product.title + " " + getVarietyTitle(item.extra) }}
                    </td>
                    <td>{{ item.amount | price }} تومان</td>
                    <td>
                      {{ item.discount_amount == null ? " ندارد" : item.discount_amount }}
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>
                      {{
                        getSumPrice(item.amount, item.discount_amount, item.quantity) | price
                      }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div id="separator" class="d-flex justify-content-between align-items-baseline my-5">
          <b-skeleton type="button" v-if="status"></b-skeleton>
          <h6 v-else class="text-color-444 weight-bold" style="white-space: nowrap;">فاکتور ها</h6>
          <div class="line bg-color-c8 w-75"></div>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <button
            id="printBtn" v-else @click="printInvoices()"
            class="py-2 px-3 border-radius7 bg-white text-color-444 fontsize12 box-shaddow20">چاپ
          </button>
        </div>
      <div id="sumInvoSec">
   <table class="showInPrint" id="sumInvoices">
            <tbody>
              <tr>
                <th scope="row" class="titleCell">مجموع تخفیفات</th>
                <td class="resultCell">
                  {{
                    order.discount_amount != 0 ? order.discount_amount : "ندارد"
                  }}
                </td>
                <td class="titleCell">هزینه ارسال</td>
                <td class="resultCell">
                  <template v-if="order.shipping_amount != 0">
                    {{ order.shipping_amount | price }}
                  </template>
                  <template v-else>
                    رایگان
                  </template>
                </td>
              </tr>

              <tr>
                <th scope="row" class="titleCell">جمع کل :</th>
                <td class="resultCell">{{ order.total_amount | price }} تومان</td>
                <td class="titleCell">نحوه ارسال</td>
                <td class="resultCell">{{ order.shipping.name }}</td>
              </tr>

              <tr>
                <th scope="row" class="titleCell">نحوه پرداخت</th>
                <template v-for="item in order.invoices">
                  <td
                    v-if="item.status == 'success'"
                    :key="item.id"
                    class="resultCell"
                  >
                    {{ findPayments(item.type, item.payments[0]) }}
                  </td>
                  <template v-if="item.type === 'both'">
                    <td class="titleCell">میزان پرداخت از کیف پول :</td>
                    <td class="resultCell">
                       {{ item.wallet_amount | price }}
                    </td>
                  </template>

                </template>
                <td class="titleCell">تاریخ سفارش</td>
                <td class="resultCell">
                  {{ moment(order.created_at).format('HH:mm YYYY/MM/DD') }}
                </td>

              </tr>
            </tbody>
          </table>
      </div>
        <div id="invoicesTable">
          <table id="invoices" class="table">
            <thead>
              <tr id="trInvoicesHead" class="text-color-444 fontsize14">
                <th scope="col" class="hideIn768">شناسه</th>
                <th scope="col">کدرهگیری</th>
                <th scope="col">تاریخ</th>
                <th scope="col">قمیت</th>
                <th scope="col">وضعیت</th>
                <th scope="col">نحوه پرداخت</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in order.invoices">
                <tr v-if="item.status == 'success'" :key="item.id">
                  <th class="hideIn768" scope="row">{{ index + 1 }}</th>
                  <td scope="row">
                    {{
                      item.payments.length != 0
                        ? item.payments[0].tracking_code
                        : "ندارد"
                    }}
                  </td>
                  <td>{{ moment(item.created_at).format('HH:mm YYYY/MM/DD') }}</td>
                  <td>{{ item.amount | price }} تومان</td>
                  <td>{{ getInvoicesStatus(item.status) }}</td>
                  <td>{{ findPayments(item.type, item.payments[0]) }}</td>
                  <td v-if="item.type === 'both'">میزان پرداخت از کیف پول: {{item.wallet_amount | price }} تومان</td>
                </tr>
              </template>
            </tbody>
          </table>



          <ul class="showInPrint">
            <span><b> شرایط و قوانین خرید از  کفش یونیک :</b></span>
            <li>
              تعویض کالا بدون عودت وجه تا 15 روز از تاریخ فاکتور حداکثر دو بار
              پس از خرید و با قیمت روز تعویض میشود
            </li>
            <li>امکان تعویض کالاهای تعویضی وجو ندارد</li>
            <li>
              کالای قابل تعویض درست به مانند حالت اولیه و باز نشده باید ارسال
              شود
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import product from "@/parts/Front/components/productDetail/classes/product";
import PagesPath from "@/parts/Front/components/PagesPath";
import { Printd } from "printd";
import {
  findPayments,
  findPriceOrder,
  getOrderStatus,
  getInvoicesStatus,
  getSumPrice,
} from "@/libraries/functions";
import {BSkeleton} from 'bootstrap-vue'

export default {
  created() {
    if (this.user == null) {
      this.$store.dispatch("front/getUserDetailFromServer");
    }
  },
  methods: {
    getCustomerAdderss() {
      return JSON.parse(this.order.address);
    },
    findPayments,
    findPriceOrder,
    getOrderStatus,
    getInvoicesStatus,
    getSumPrice,
    // دریافت عنوان
    getVarietyTitle(val) {
      let variety = JSON.parse(val);
      return product.getVarietyTitle(variety);
    },
    printInvoices() {
      // با کلیک بروی دکمه چاپ خروجی چاپگر نمایش داده شود
      const cssText = `

body {-webkit-print-color-adjust: exact;}
.showInPrint{
  display: block;
}
td {border-radius:0px!important;}
      #sumInvoSec{
        display:flex;
        justify-content:center !important;
      }
#headerInPrint {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
#separator{
  display: none;
}

#customerTabel tbody th,#customerTabel tbody td {
  border: 1px solid black !important;
}
#atlasTabel tbody th,#atlasTabel tbody td {
  border: 1px solid black !important;
}
#customerTabel tbody tr,
#atlasTabel tbody tr {
  border-top: 1px solid black !important;
}
#customerTabel,
#atlasTabel {
    direction: rtl;

  width: 100% !important;
  text-align: center;
}
tbody tr .markCell {
  background-color: #b3b3b3;
  width: 20% !important;
}
tbody tr .markCellHeader {
  background-color: #a3a3a3;
}
tbody tr .last_col {
  width: 20% !important;
}
tbody tr .col_2 {
  width: 40% !important;
}
#sumInvoices {
  width: 100%;
    direction: rtl;

  text-align: center;
}
#sumInvoices tbody th,#sumInvoices tbody td {
  border: 1px solid black !important;
}
#sumInvoices tbody tr {
  border-top: 1px solid black !important;
}
#sumInvoices .titleCell {
  width: 250px !important;
  background-color: #b3b3b3;
}
#sumInvoices .resultCell {
  width: 250px !important;
}
ul li {
  padding: 5px;
  padding-right: 20px;
}
ul {
    direction: rtl;

  margin-top: 10px;

}
  h1 {
    color: black;
    font-family: sans-serif;
  }
  .table {
    direction:rtl;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }

  #invoices.table th,
    #invoices.table td ,
    #itemTable .table th,
    #itemTable .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

    #invoices.table thead th ,#itemTable .table thead th{
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
`;
      const d = new Printd();
      d.print(document.getElementById("orderSection"), [cssText]);
    },
  },
  components: {
    PagesPath,BSkeleton
  },
  beforeCreate() {
    // قبل از ساخت صفحه درخواست برای دریافت سفارش به سرور زده شود
    this.$store.dispatch(
      "front/getSingleOrderFromServer",
      this.$route.params.id
    );
  },
  computed: {
    // گرفتن وضعیت دریافت سفارش از سرور
    status() {
      return this.$store.getters["front/getOrderStatus"];
    },
    // دریافت سفارش از ویو ایکس
    order() {
      return this.$store.getters["front/getOrder"];
    },

    user() {
      return this.$store.getters["front/getUserDetail"];
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
};
</script>
<style scoped>
/* افزودن کدهای سی اس به قالب های اضافه شده */
.table {
  width: 90%;
  margin: auto;
}
#separator {
  margin-right: 15%;
  margin-left: 15%;
}
#printBtn {
  background-color: #121314 !important;
  color: white !important;
  border: unset !important;
}
#trInvoicesHead,
#trItemTable {
  border-top: solid transparent !important;
}


#itemTable {
  width: 90%;
  overflow: auto;
  margin: auto;
  margin-top: 15px;
}
#invoicesTable {
  width: 90%;
  overflow: auto;
  margin: auto;
}
#showInPrint {
  display: block;
}

#atlasLogoInPrint {
  position: fixed;
  top: 0;
  right: 0;
}
/*  */

.showInPrint {
  display: none;
}
#sumInvoSec{
  width: 100%;
}


</style>
